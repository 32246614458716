import React from "react";
import {
  SimpleGrid,
  Card,
  Group,
  Badge,
  Title,
  Text,
  Image,
  Button,
  ScrollArea,
  Container,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { Utopia_Cover, Utopia_Character_Sheet } from "../content/img";

const Downloads = () => {
  return (
    <ScrollArea style={{ width: "100vw", height: "90vh" }}>
      <SimpleGrid className="download-grid" cols={{ base: 1, sm: 1, lg: 2 }}>
        <Container justify>
          <Card
            shadow="sm"
            maw={600}
            m={30}
            padding="lg"
            radius="md"
            withBorder
          >
            <Card.Section>
              <Image
                src={Utopia_Cover}
                height={800}
                alt="Utopia TTRPG PDF Cover"
              />
            </Card.Section>

            <Group justify="space-between" mt="md" mb="xs">
              <Title order={2} fw={500}>
                Utopia Player's Guide
              </Title>
              <Badge size="md">Last Updated: Jan 8, 2025 (v0.9)</Badge>
            </Group>

            <Text size="sm" c="dimmed">
              Everything you need to get started playing the Utopia TTRPG with
              your friends! A comprehensive guide with information on species,
              character creation, combat and roleplay rules, and so much more!
            </Text>

            <Link
              to="https://www.dropbox.com/scl/fi/87nt2e1xxlyubk5zrlbah/SEARCHABLE-Utopia-TTRPG-V0.9-Illustrated.pdf?rlkey=jgjtudcp4j5yd7hjvga9cfmuu&st=pl2kv1qi&dl=0"
              target="_blank"
            >
              <Button fullWidth mt="md" radius="md">
                View on Dropbox
              </Button>
            </Link>
          </Card>
        </Container>
        <Container>
          <Card
            maw={600}
            shadow="sm"
            m={30}
            padding="lg"
            radius="md"
            withBorder
          >
            <Card.Section>
              <Image
                src={Utopia_Character_Sheet}
                height={800}
                alt="Utopia TTRPG PDF Cover"
              />
            </Card.Section>

            <Group justify="space-between" mt="md" mb="xs">
              <Title order={2} fw={500}>
                Character Sheet
              </Title>
              <Badge size="md">Last Updated: Jan 8, 2025 (v0.9)</Badge>
            </Group>

            <Text size="sm" c="dimmed">
              The current version of the Utopia TTRPG Character Sheet. There are
              likely always going to be updates to this file, so check back
              often for changes and updates!
            </Text>

            <Link
              to="https://www.dropbox.com/scl/fi/ljlixvzjm8o6mq20iu4ym/Basic-Character-Sheet-EDITABLE.pdf?rlkey=5qjdt41ub44dheu5b43eptofi&st=8016gin7&dl=0"
              target="_blank"
            >
              <Button fullWidth mt="md" radius="md">
                Make your Character Now!
              </Button>
            </Link>
          </Card>
        </Container>
      </SimpleGrid>
    </ScrollArea>
  );
};

export default Downloads;
